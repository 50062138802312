import Layout from "../components/Layout";
import { Container, Box, Spacer, Heading, Divider } from "@chakra-ui/react";
import Anchor from "../components/Anchor";
import { SEO } from "../components/SEO";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const ThankYouPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title={t("common.thank_you_for_caring")} />
      <Spacer />
      <Box w="full" bg="whiteAlpha.800" padding={5} rounded="xl">
        <Container maxW="xl" minHeight="30vh" textAlign={"center"}>
          <Heading fontSize="3xl">{t("common.thank_you_for_caring")}</Heading>
          <Divider margin={5} />
          <Anchor to="/">{t("common.notify_more_partners")}</Anchor>
        </Container>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ThankYouPage;
